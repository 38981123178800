<template>
  <step-wrapper title="Мониторинги" class="consolidated-report-step5">
    <base-list-view
      v-if="dataSource.MonitoringContent"
      :dataSource="dataSource.MonitoringContent.Monitorings"
      :headers="headers"
      hide-default-footer
      :showSettings="false"
      notShowSelect
      @onRefresh="actual"
      show-expand
      :notShowActions="['add', 'delete', 'refresh']"
      @customHandle="(e) => (editItem = e)"
      not-filter
    >
      <template v-slot:expanded-item="{ headers, item }">
        <base-table-expanded :colspan="headers.length">
          <v-row>
            <v-col>
              <table>
                <tr>
                  <td>Количество фактов:</td>
                  <td>{{ item.ViolationCount }}</td>
                </tr>
                <tr>
                  <td>
                    <div>Выявленные несоответствия:</div>
                  </td>
                  <td>{{ item.ViolationsText }}</td>
                </tr>
              </table>
            </v-col>
            <v-col>
              <table>
                <tr>
                  <td>Перечень корректирующих мер:</td>
                  <td>{{ item.TasksText }}</td>
                </tr>
                <tr>
                  <td>
                    <div>Сведения о выполнении:</div>
                  </td>
                  <td>{{ item.ExecutionText }}</td>
                </tr>
              </table>
            </v-col>
          </v-row>
        </base-table-expanded>
      </template>
    </base-list-view>
    <v-form :readonly="readonly" v-model="valid">
      <div class="mt-12">
        <div class="title">Заключение</div>
        <v-textarea
          v-if="dataSource.MonitoringContent"
          :value="dataSource.MonitoringContent.Conclusion"
          @change="dataSource.MonitoringContent.Conclusion = $event"
          placeholder="Заключение..."
        ></v-textarea>
      </div>
    </v-form>
    <edit-menu :item.sync="editItem" @save="save"></edit-menu>
  </step-wrapper>
</template>
<script>
import UnsavedMixin from "@/mixins/UnsavedMixin.js";
import stepWrapper from "../stepWrapper.vue";
import CreateAndEditMixin from "@/mixins/CreateAndEditMixin.js";

import { defaultSummaryReport } from "@/data/defaultObjects";
import SummaryReportService from "@/services/SummaryReportService";
import BaseListView from "@/layouts/BaseListView.vue";
import BaseTableExpanded from "@/layouts/BaseTableExpanded.vue";
import editMenu from "./editMenu";

export default {
  name: "consolidated-report-step5",
  components: {
    stepWrapper,
    BaseListView,
    BaseTableExpanded,
    editMenu,
  },
  mixins: [CreateAndEditMixin, UnsavedMixin],
  inject: ["setDefaultData"],
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      apiService: SummaryReportService.step5,
      getDefaultObject: defaultSummaryReport.step5(),
      editItem: null,
      valid: false,
      rules: {
        required: (value) => (!!value && !!value.length) || "Укажите значение.",
      },

      loading: false,
      dataSource: {},

      headers: [
        { text: "Показатели", value: "Name" },
        { text: "Количество", value: "MonitoringCount", width: "1%" },
      ],
    };
  },
  computed: {},
  watch: {
    isUnsaved(val) {
      this.$emit("update:isUnsaved", val);
    },
    loading: {
      immediatle: true,
      handler(val) {
        this.$emit("update:loading", val);
      },
    },
  },
  async mounted() {
    await this.init();
  },
  methods: {
    async init() {
      await this.baseInit();
      this.setDefaultData(this.dataSource);
    },
    actual() {
      this.init({
        query: {
          actual: true,
        },
      });
    },
  },
};
</script>
<style lang="scss">
.consolidated-report-step5 {
}
</style>
